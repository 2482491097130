import { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Home = lazy(() => import("./Home"));
const About = lazy(() => import("./About"));
const Work = lazy(() => import("./Work"));
const Life = lazy(() => import("./Life"));
const Sailing = lazy(() => import("./Sailing"));
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Suspense>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="about"
            element={
              <Suspense>
                <About />
              </Suspense>
            }
          />
          <Route
            path="work"
            element={
              <Suspense>
                <Work />
              </Suspense>
            }
          />
          <Route
            path="concerts"
            element={
              <Suspense>
                <Work />
              </Suspense>
            }
          />
          <Route
            path="sailing"
            element={
              <Suspense>
                <Sailing />
              </Suspense>
            }
          />
          <Route
            path="life"
            element={
              <Suspense>
                <Life />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
